import plural from 'pluralize';
import { slice } from 'ramda';

export const capitalizeFirstLetter = (value) => {
  if (typeof value === 'string') {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  return value;
};

export const truncateText = (text, start, end) => {
  if (text) {
    return text.length <= end
      ? text.substring(start, end)
      : `${text.substring(start, end)} ...`;
  }

  return '';
};

/*
  This function checks if a string is longer than X chars and if so, divides the string up by sentence.  Currently it may split a sentence on the comma, period, question mark or exclamation point.
*/
export const sentenceBreak = (str, length) => {
  const matchingRegularExpression = str.match(/[^.,!?]+[.,!?]+/g);
  if (str.length <= length) {
    return str;
  } else if (matchingRegularExpression !== null) {
    return matchingRegularExpression[0];
  } else {
    return `${str.slice(0, length - 3)}...`;
  }
};

export const pluralize = (str, count) => plural(str, count);

export const getFirstInitial = (name) => slice(0, 1, name || '').toUpperCase();

export const formatNumber = (num) => {
  return new Intl.NumberFormat('en-GB', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(num);
};
